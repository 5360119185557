var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "../../functions";
import { Bot5MiniappAPI } from "../../public/bot5/api";
import { Select, ThemeProvider, Table, TextInput, Icon, Button, Modal, TextArea, Spin } from "@gravity-ui/uikit";
import { Bot5API } from "../../api/admin/bot5tasks/api";
import { Magnifier, PencilToLine, Plus, TrashBin, Minus } from '@gravity-ui/icons';
var TYPES = {
    'MULTIPLE_CHOICE': 'Несколько вариантов',
    'SINGLE_CHOICE': 'Один вариант',
    'TRUE_FALSE': 'Данетка',
    'FREETEXT': 'Ввод ответа',
};
export default function AdminBot5TasksPage() {
    var _a, _b;
    var _c = useContext(GlobalContext), startLoading = _c.startLoading, finishLoading = _c.finishLoading;
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var _e = useState([]), tasks = _e[0], setTasks = _e[1];
    var _f = useState({}), subjects = _f[0], setSubjects = _f[1];
    var _g = useState(0), currentSubjectId = _g[0], setCurrentSubjectId = _g[1];
    var _h = useState(''), search = _h[0], setSearch = _h[1];
    var _j = useState(null), currentTaskToEditId = _j[0], setCurrentTaskToEditId = _j[1];
    var _k = useState([]), topics = _k[0], setTopics = _k[1];
    var _l = useState([]), sources = _l[0], setSources = _l[1];
    var _m = useState(0), optionsCount = _m[0], setOptionsCount = _m[1];
    function UpdateAll() {
        return Promise.all([
            Bot5MiniappAPI.GetSubjects().then(function (data) {
                var _a;
                setSubjects(data);
                setCurrentSubjectId(+((_a = Object.keys(data)) === null || _a === void 0 ? void 0 : _a[0]));
            }),
            Bot5API.GetTopicsAndSources().then(function (data) {
                setSources(data.sources);
                setTopics(data.topics);
            }),
        ]);
    }
    function FetchTasks(loading) {
        if (loading === void 0) { loading = true; }
        loading && setLoading(true);
        if (currentSubjectId) {
            Bot5API.GetBySubject(currentSubjectId).then(function (data) {
                setTasks(data.reverse());
                loading && setLoading(false);
            });
        }
    }
    useEffect(function () {
        startLoading();
        UpdateAll().finally(function () {
            finishLoading();
        });
    }, []);
    useEffect(function () {
        FetchTasks();
    }, [currentSubjectId]);
    var taskToEdit = useMemo(function () {
        var _a, _b;
        var task = tasks.find(function (item) { return item.id === currentTaskToEditId; });
        setOptionsCount((_b = (_a = task === null || task === void 0 ? void 0 : task.options) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 1);
        return task;
    }, [tasks, currentTaskToEditId]);
    var formRef = useRef();
    return (
    // @ts-ignore
    React.createElement(ThemeProvider, { theme: "light" },
        React.createElement("div", { className: "admin-bot5-page" },
            React.createElement("div", { className: "container" },
                React.createElement("h2", { className: "admin-bot5-page__title" }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u0437\u0430\u0434\u0430\u0447 \u0431\u043E\u0442\u0430"),
                React.createElement("div", { className: "admin-bot5-page__select" },
                    React.createElement(Select, { options: Object.entries(subjects).map(function (_a) {
                            var id = _a[0], title = _a[1];
                            return ({
                                content: title,
                                value: String(id)
                            });
                        }), size: "l", placeholder: "\u041F\u0440\u0435\u0434\u043C\u0435\u0442", label: "\u041F\u0440\u0435\u0434\u043C\u0435\u0442", value: [String(currentSubjectId)], onUpdate: function (_a) {
                            var val = _a[0];
                            return setCurrentSubjectId(+val);
                        } }),
                    "\u00A0",
                    React.createElement(TextInput, { size: "l", placeholder: "\u041F\u043E\u0438\u0441\u043A", value: search, className: "admin-bot5-page__search-input", onUpdate: function (val) { return setSearch(val); }, startContent: React.createElement(React.Fragment, null,
                            "\u00A0",
                            React.createElement(Icon, { data: Magnifier }),
                            "\u00A0") }),
                    "\u00A0",
                    React.createElement(Button, { view: "outlined-warning", onClick: function () { return setCurrentTaskToEditId(0); }, size: "l" },
                        React.createElement(Icon, { data: Plus }),
                        "\u0421\u043E\u0437\u0434\u0430\u0442\u044C")),
                loading
                    ? React.createElement(Spin, { size: "l" })
                    : React.createElement("div", null,
                        React.createElement(Table, { data: tasks.filter(function (item) { return item.text.toLowerCase().includes(search.toLowerCase()); }).map(function (item) { return ({
                                id: item.id,
                                text: item.text,
                                type: TYPES[item.type],
                                difficulty: item.difficulty,
                                metro: item.metro ? 'Да' : 'Нет',
                                button: React.createElement(React.Fragment, null,
                                    React.createElement(Button, { view: "outlined-info", onClick: function () { return setCurrentTaskToEditId(item.id); } },
                                        React.createElement(Icon, { data: PencilToLine })),
                                    "\u00A0",
                                    React.createElement(Button, { view: "outlined-danger", onClick: function () { return Bot5API.DeleteTask(item.id).finally(function () { return FetchTasks(false); }); } },
                                        React.createElement(Icon, { data: TrashBin })))
                            }); }), columns: [
                                { id: 'id', width: 50 },
                                { id: 'button', name: '' },
                                { id: 'text', name: 'Текст задачи', className: 'admin-bot5-page__text-td' },
                                { id: 'type', name: 'Тип', width: 100 },
                                { id: 'difficulty', name: 'Сложность', width: 50 },
                                { id: 'metro', name: 'Можно в метро', width: 50 },
                            ], emptyMessage: "\u0417\u0430\u0434\u0430\u0447\u0438 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B" })),
                React.createElement(Modal, { open: currentTaskToEditId != null, onClose: function () { return setCurrentTaskToEditId(null); } },
                    React.createElement("form", { className: "admin-bot5-page__modal-wrapper modern", ref: formRef, onSubmit: function (evt) {
                            evt.preventDefault();
                            if (formRef.current) {
                                (currentTaskToEditId ? Bot5API.UpdateTask : Bot5API.CreateTask)(formRef.current)
                                    .finally(function () {
                                    FetchTasks();
                                    setCurrentTaskToEditId(null);
                                });
                            }
                        } },
                        React.createElement("h2", null, currentTaskToEditId ? 'Редактировать задачу' : 'Создать задачу'),
                        currentTaskToEditId ? React.createElement("input", { type: "hidden", name: "id", value: currentTaskToEditId }) : null,
                        React.createElement("input", { type: "hidden", name: "subject_id", value: currentSubjectId }),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0422\u0435\u043A\u0441\u0442 \u0437\u0430\u0434\u0430\u0447\u0438"),
                            React.createElement(TextArea, { name: "text", defaultValue: taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.text, size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0412\u0430\u0440\u0438\u0430\u043D\u0442\u044B \u043E\u0442\u0432\u0435\u0442\u0430"),
                            Array(optionsCount).fill(0).map(function (_, i) {
                                var _a;
                                return (React.createElement("div", { key: i, style: { display: 'flex', alignItems: 'center', margin: '5px 0' } },
                                    React.createElement("span", null,
                                        i + 1,
                                        ".\u00A0"),
                                    React.createElement(TextInput, { name: "options[]", defaultValue: (_a = taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.options) === null || _a === void 0 ? void 0 : _a[i] })));
                            }),
                            React.createElement("div", null,
                                React.createElement(Button, { onClick: function () { return setOptionsCount(optionsCount + 1); } },
                                    React.createElement(Icon, { data: Plus })),
                                "\u00A0",
                                React.createElement(Button, { onClick: function () { return setOptionsCount(optionsCount - 1); }, disabled: !optionsCount },
                                    React.createElement(Icon, { data: Minus })))),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u041E\u0442\u0432\u0435\u0442"),
                            React.createElement(TextInput, { name: "answer", defaultValue: taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.answer, size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0422\u0438\u043F \u0437\u0430\u0434\u0430\u0447\u0438"),
                            React.createElement(Select, { width: "max", name: "type", defaultValue: [(_a = taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.type) !== null && _a !== void 0 ? _a : 'TRUE_FALSE'], options: Object.entries(TYPES).map(function (item) { return ({
                                    content: item[1],
                                    value: item[0],
                                }); }), size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0421\u043B\u043E\u0436\u043D\u043E\u0441\u0442\u044C"),
                            React.createElement(Select, { width: "max", name: "difficulty", defaultValue: [(_b = taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.difficulty) !== null && _b !== void 0 ? _b : '1'], options: [
                                    { content: '1', value: '1' },
                                    { content: '2', value: '2' },
                                    { content: '3', value: '3' },
                                ], size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A"),
                            React.createElement(Select, { width: "max", name: "source_id", defaultValue: [(taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.source_id) ? String(taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.source_id) : 'null'], options: __spreadArray([
                                    { content: '—', value: 'null' }
                                ], sources.filter(function (source) { return source.subject_id === currentSubjectId; })
                                    .map(function (source) { return ({
                                    content: source.title,
                                    value: String(source.id),
                                }); }), true), size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u0422\u0435\u043C\u0430"),
                            React.createElement(Select, { width: "max", name: "topic_id", defaultValue: [(taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.topic_id) ? String(taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.topic_id) : 'null'], options: __spreadArray([
                                    { content: '—', value: 'null' }
                                ], topics.filter(function (topic) { return topic.subject_id === currentSubjectId; })
                                    .map(function (topic) { return ({
                                    content: topic.name,
                                    value: String(topic.id),
                                }); }), true), size: "l" })),
                        React.createElement("div", { style: { margin: '10px 0' } },
                            React.createElement("span", null, "\u041C\u043E\u0436\u043D\u043E \u0432 \u043C\u0435\u0442\u0440\u043E"),
                            React.createElement(Select, { width: "max", name: "metro", defaultValue: [(taskToEdit === null || taskToEdit === void 0 ? void 0 : taskToEdit.metro) ? '1' : '0'], options: [
                                    { content: 'Да', value: '1' },
                                    { content: 'Нет', value: '0' },
                                ], size: "l" })),
                        React.createElement(Button, { size: "l", view: "flat-success", width: "max", selected: true, type: "submit" }, currentTaskToEditId ? 'Сохранить' : 'Создать')))))));
}
