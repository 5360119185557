var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Select, Text, TextInput } from '@gravity-ui/uikit';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EMAIL_CHECK_REGEXP } from '../../../functions';
import { Bot5MiniappAPI } from '../api';
import { DoHapticFeedback } from '../index';
import { REGIONS } from '../regions';
var GradesMap = {
    '1': '1 класс',
    '2': '2 класс',
    '3': '3 класс',
    '4': '4 класс',
    '5': '5 класс',
    '6': '6 класс',
    '7': '7 класс',
    '8': '8 класс',
    '9': '9 класс',
    '10': '10 класс',
    '11': '11 класс',
    'GRADUATED': 'Уже окончил школу',
};
export function GetDefaultUserInfoData() {
    return {
        first_name: '',
        last_name: '',
        patronymic: '',
        grade: 'GRADUATED',
        school: '',
        school_region: 'Москва',
        email: '',
        subject_id: 1,
    };
}
export default function UserInfoForm(props) {
    var _a;
    var WebApp = props.webApp, currentDataRef = props.currentDataRef;
    var _b = useState(false), pending = _b[0], setPending = _b[1];
    var _c = useState((_a = props.initData) !== null && _a !== void 0 ? _a : GetDefaultUserInfoData()), initData = _c[0], setInitData = _c[1];
    var _d = useState([]), allSubjects = _d[0], setAllSubjects = _d[1];
    var lastSavedValueRef = useRef(JSON.stringify(initData));
    var isValid = useMemo(function () {
        return Boolean(initData.first_name
            && initData.last_name
            && initData.grade
            && initData.school
            && initData.school_region
            && initData.email && EMAIL_CHECK_REGEXP.test(initData.email));
    }, [initData]);
    useEffect(function () {
        Bot5MiniappAPI.GetSubjects()
            .then(function (subjects) { return setAllSubjects(subjects); });
    }, []);
    useEffect(function () {
        currentDataRef.current = JSON.stringify(initData);
    }, [initData]);
    return (React.createElement("form", { className: "modern bot5-page__form" },
        React.createElement("h1", null, props.modification ? 'Мои данные' : 'Регистрация'),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "\u0424\u0430\u043C\u0438\u043B\u0438\u044F ",
                React.createElement("span", { className: "label-required " + (initData.last_name ? 'label-required-valid' : '') }, "*")),
            React.createElement(TextInput, { name: "last_name", value: initData.last_name, onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { last_name: val })); }, size: "l", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "\u0418\u043C\u044F ",
                React.createElement("span", { className: "label-required " + (initData.first_name ? 'label-required-valid' : '') }, "*")),
            React.createElement(TextInput, { name: "first_name", value: initData.first_name, onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { first_name: val })); }, size: "l", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" }, "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E"),
            React.createElement(TextInput, { name: "patronymic", value: initData.patronymic, onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { patronymic: val })); }, size: "l", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "\u0412\u0430\u0448 \u043A\u043B\u0430\u0441\u0441 ",
                React.createElement("span", { className: "label-required " + (initData.grade ? 'label-required-valid' : '') }, "*")),
            React.createElement(Select, { name: "grade", value: [initData.grade], onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { grade: val[0] })); }, options: Object.entries(GradesMap).map(function (_a) {
                    var grade = _a[0], text = _a[1];
                    return ({
                        value: grade,
                        content: text,
                    });
                }), size: "l", width: "max", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                initData.grade === 'GRADUATED' ? 'Школа, которую вы окончили' : 'Ваша школа',
                " ",
                React.createElement("span", { className: "label-required " + (initData.school ? 'label-required-valid' : '') }, "*")),
            React.createElement(TextInput, { size: "l", value: initData.school, onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { school: val })); }, onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "\u0420\u0435\u0433\u0438\u043E\u043D \u0432\u0430\u0448\u0435\u0439 \u0448\u043A\u043E\u043B\u044B ",
                React.createElement("span", { className: "label-required " + (initData.school_region ? 'label-required-valid' : '') }, "*")),
            React.createElement(Select, { value: [initData.school_region], onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { school_region: val[0] })); }, options: REGIONS.map(function (region) { return ({
                    value: region,
                    content: region,
                }); }), filterable: true, size: "l", width: "max", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "Email",
                React.createElement("span", { className: "label-required " + (initData.email ? 'label-required-valid' : '') }, "*")),
            React.createElement(TextInput, { size: "l", error: Boolean(initData.email) && !EMAIL_CHECK_REGEXP.test(initData.email), value: initData.email, onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { email: val })); }, onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement("label", { className: "label" },
            React.createElement(Text, { className: "label-title" },
                "\u041F\u0440\u0435\u0434\u043C\u0435\u0442 (\u043C\u043E\u0436\u043D\u043E \u0431\u0443\u0434\u0435\u0442 \u043F\u043E\u0442\u043E\u043C \u043F\u043E\u043C\u0435\u043D\u044F\u0442\u044C)",
                React.createElement("span", { className: "label-required " + (initData.subject_id ? 'label-required-valid' : '') }, "*")),
            React.createElement(Select, { name: "grade", value: [String(initData.subject_id)], onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { subject_id: +val[0] })); }, options: Object.entries(allSubjects).map(function (_a) {
                    var id = _a[0], subjectTitle = _a[1];
                    return ({
                        value: String(id),
                        content: subjectTitle,
                    });
                }), size: "l", width: "max", onFocus: function () {
                    DoHapticFeedback(WebApp);
                } })),
        React.createElement(Button, { width: "max", selected: true, view: "flat", size: "l", onClick: function () {
                setPending(true);
                // @ts-ignore
                Bot5MiniappAPI.SubmitMiniapp(WebApp === null || WebApp === void 0 ? void 0 : WebApp.initData, props.state, JSON.stringify(initData), props.modification ? 'SET_SETTINGS' : '')
                    .then(
                // @ts-ignore
                function () { var _a, _b; return (_b = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp) === null || _b === void 0 ? void 0 : _b.close(); }, function (err) { return console.error('[Miniapp Handler] Something went wrong', err); })
                    .finally(function () {
                    setPending(false);
                });
                DoHapticFeedback(WebApp);
            }, disabled: !isValid, loading: pending }, props.modification ? 'Сохранить' : 'Отправить')));
}
