import * as React from "react";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { useEffect, useRef, useState } from "react";
import { BunkerAPI } from "../../api/BunkerAPI";
export default function PublicNote(_a) {
    var note = _a.note;
    var _b = useState(''), content = _b[0], setContent = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var firstLoaded = useRef(true);
    useEffect(function () {
        if (firstLoaded.current) {
            firstLoaded.current = false;
            BunkerAPI.GetPublicNode(note)
                .then(function (text) { return setContent(text); })
                .finally(function () { return setLoading(false); });
        }
    });
    return (React.createElement("div", { className: "jobs-page" },
        React.createElement("div", { className: "jobs-page__left-sidebar" },
            React.createElement(Link, { to: "/jobs", className: "jobs-page__logo" },
                React.createElement("img", { src: "/img/apo-team.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F \u0410\u041F\u041E Team" }),
                React.createElement("span", { className: "jobs-page__logo-text" }, "\u0420\u0430\u0431\u043E\u0442\u0430 \u0432 \u0410\u041F\u041E"))),
        React.createElement("div", { className: "jobs-page__main-part" },
            React.createElement("div", { className: "markdown" }, loading
                ? 'Загружаем контент...'
                : (content
                    ? React.createElement(Markdown, { components: {
                            a: function (_a) {
                                var children = _a.children, href = _a.href;
                                return (React.createElement("a", { href: href !== null && href !== void 0 ? href : '#', className: "text-link", target: "_blank" }, children));
                            },
                        } }, content)
                    : 'Ничего не найдено')))));
}
