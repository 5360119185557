import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputLabel } from "../../../components/Form/InputLabel";
import { FormError, FormSuccess } from "../../../components/Form";
import LoginAPI from "../../../api/request/login/api";
var TIME_BEFORE_SENDING_NEW_CODE = 30;
export default function AuthCodeForm() {
    var searchParams = useSearchParams()[0];
    var retpath = searchParams.get('retpath');
    var _a = useState(''), code = _a[0], setCode = _a[1];
    var _b = useState(''), codeError = _b[0], setCodeError = _b[1];
    var _c = useState(''), newCodeError = _c[0], setNewCodeError = _c[1];
    var _d = useState(''), newCodeSuccess = _d[0], setNewCodeSuccess = _d[1];
    var _e = useState(TIME_BEFORE_SENDING_NEW_CODE), timer = _e[0], setTimer = _e[1];
    var _f = useState(false), submitting = _f[0], setSubmitting = _f[1];
    var _g = useState(false), newCodeSending = _g[0], setNewCodeSending = _g[1];
    var login = localStorage.getItem('tgusername') || '';
    var navigate = useNavigate();
    function onCodeChange(evt) {
        setCode(evt.target.value);
    }
    function sendNewCode(evt) {
        evt.preventDefault();
        var data = new FormData(document.querySelector('#auth-code-form'));
        setNewCodeError('');
        setNewCodeSuccess('');
        setNewCodeSending(true);
        LoginAPI.SendCode(login)
            .then(function (status) {
            setNewCodeSending(false);
            setTimer(TIME_BEFORE_SENDING_NEW_CODE);
            if (status === 'ok') {
                setNewCodeSuccess('Новый код отправлен. Будьте внимательны, старый код больше не работает');
            }
            else {
                setNewCodeError("\u0412\u043E\u0437\u043D\u0438\u043A\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0435 \u043A\u043E\u0434\u0430, \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 <a href=\"".concat(retpath ? "/auth/login?retpath=".concat(encodeURIComponent(retpath)) : '/auth/login', "\" class=\"text-link\">\u0432\u043E\u0439\u0442\u0438</a> \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E"));
            }
        });
    }
    function submitCode(evt) {
        evt.preventDefault();
        var data = new FormData(document.querySelector('#auth-code-form'));
        if (code.trim() === '') {
            setCodeError('Введите, пожалуйста, код');
            return;
        }
        setCodeError('');
        setSubmitting(true);
        LoginAPI.Login(code)
            .then(function (status) {
            setSubmitting(false);
            switch (status) {
                case 'forbidden':
                    setCodeError('Нет доступа');
                    break;
                case 'empty':
                    setCodeError('Введите, пожалуйста, код');
                    break;
                case 'wrong':
                    setCodeError('Неправильный код');
                    break;
                case 'old':
                    setCodeError('Код устарел, получите новый, пожалуйста (код действует 15 минут)');
                    break;
                case 'ok':
                    localStorage.removeItem('tgusername');
                    location.href = retpath !== null && retpath !== void 0 ? retpath : '/';
                    break;
                default:
                    setCodeError('Возникла непредвиденная ошибка, попробуйте еще раз позже');
            }
        });
    }
    useEffect(function () {
        var interval = setInterval(function () { return setTimer(timer > 0 ? timer - 1 : 0); }, 1000);
        return function () { return clearInterval(interval); };
    });
    return (React.createElement("form", { id: "auth-code-form", className: "login-page__form", onSubmit: submitCode },
        React.createElement("div", { className: "login-page__logo" },
            React.createElement("img", { src: "/img/superapp.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F" })),
        React.createElement(InputLabel, { title: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434", name: "code", value: code, valueSetter: setCode, placeholder: "123456", autoComplete: "off", error: codeError }),
        React.createElement("input", { type: "hidden", name: "login", value: login }),
        React.createElement("button", { className: "login-page__button", disabled: submitting }, "\u0412\u043E\u0439\u0442\u0438"),
        React.createElement("button", { className: "login-page__button login-page__new-code-button", onClick: sendNewCode, hidden: login === '', disabled: timer > 0 || newCodeSending },
            "\u041F\u0440\u0438\u0441\u043B\u0430\u0442\u044C \u043D\u043E\u0432\u044B\u0439 \u043A\u043E\u0434\u00A0",
            React.createElement("span", { className: "login-page__timer", hidden: timer === 0 },
                "0:",
                (timer < 10 ? '0' : '') + timer)),
        React.createElement("div", { className: "label label-small-margins" },
            React.createElement(FormError, { text: newCodeError }),
            React.createElement(FormSuccess, { text: newCodeSuccess }))));
}
