import * as React from "react";
import Markdown from "react-markdown";
import { useEffect, useRef, useState } from "react";
import { BunkerAPI } from "../../api/BunkerAPI";
export default function PrivateNote(_a) {
    var note = _a.note;
    var _b = useState(''), content = _b[0], setContent = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var firstLoaded = useRef(true);
    useEffect(function () {
        if (firstLoaded.current) {
            firstLoaded.current = false;
            setTimeout(function () {
                BunkerAPI.GetPrivateNode(note)
                    .then(function (text) { return setContent(text); })
                    .finally(function () { return setLoading(false); });
            }, 500);
        }
    });
    return (React.createElement("div", { className: "container medium-container" },
        React.createElement("div", { className: "markdown" }, loading
            ? 'Загружаем контент...'
            : (content
                ? React.createElement(Markdown, { components: {
                        a: function (_a) {
                            var children = _a.children, href = _a.href;
                            return (React.createElement("a", { href: href !== null && href !== void 0 ? href : '#', className: "text-link", target: "_blank" }, children));
                        },
                    } }, content)
                : 'Ничего не найдено'))));
}
