var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AdminAPI } from "../api";
var Bot5API = /** @class */ (function (_super) {
    __extends(Bot5API, _super);
    function Bot5API() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = Bot5API;
    Bot5API.GetBySubject = function (subjectId) { return _a.AlwaysResolvingQuery({
        url: 'admin/bot5tasks/GetBySubject/',
        data: {
            subject_id: subjectId,
        },
        dataCallback: function (data) { return data.tasks; },
        defaultValueCallback: function () { return []; }
    }); };
    Bot5API.GetTopicsAndSources = function () { return _a.AlwaysResolvingUnchangedQuery({
        url: 'admin/bot5tasks/GetTopicsAndSources/',
        defaultValueCallback: function () { return ({
            result: 'ok',
            topics: [],
            sources: [],
        }); },
    }); };
    Bot5API.CreateTask = function (form) { return _a.VoidQuery({
        url: 'admin/bot5tasks/CreateTask/',
        data: form,
    }); };
    Bot5API.UpdateTask = function (form) { return _a.VoidQuery({
        url: 'admin/bot5tasks/UpdateTask/',
        data: form,
    }); };
    Bot5API.DeleteTask = function (taskId) { return _a.VoidQuery({
        url: 'admin/bot5tasks/DeleteTask/',
        data: {
            id: taskId
        },
    }); };
    return Bot5API;
}(AdminAPI));
export { Bot5API };
