import { PaperPlane } from '@gravity-ui/icons';
import { Button, Icon, Spin, ThemeProvider } from '@gravity-ui/uikit';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bot5MiniappAPI } from '../api';
import { GetDefaultUserInfoData } from '../forms/UserInfoForm';
import { DoHapticFeedback } from '../index';
import FiltersForm from "../forms/FiltersForm";
export default function Bot5FiltersPage() {
    var _a;
    var _b = useState(false), loaded = _b[0], setLoaded = _b[1];
    var _c = useState(true), correctState = _c[0], setCorrectState = _c[1];
    var _d = useState(0), validation = _d[0], setValidation = _d[1];
    var _e = useState(GetDefaultUserInfoData), userInitData = _e[0], setUserInitData = _e[1];
    // @ts-ignore
    var WebApp = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp;
    useEffect(function () {
        import('../telegram.js').finally(function () {
            console.log(loaded);
            var counter = 0;
            var interval = setInterval(function () {
                var _a, _b;
                ++counter;
                if (counter > 30) { // ~1.5 sec
                    clearInterval(interval);
                    setLoaded(true);
                    setValidation(-1);
                }
                // @ts-ignore
                var initData = (_b = (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp) === null || _b === void 0 ? void 0 : _b.initData;
                if (initData) {
                    clearInterval(interval);
                    Bot5MiniappAPI.CheckState(initData, 'ANY')
                        .then(function (res) {
                        setValidation(res.valid ? 1 : -1);
                        setCorrectState(res.state_correct);
                    })
                        .finally(function () {
                        setLoaded(true);
                    });
                }
            }, 50);
        });
    }, []);
    return (React.createElement("div", { className: "bot5-page" },
        React.createElement(ThemeProvider, { theme: "system" }, validation === -1 || !correctState
            ? (React.createElement("form", { className: "modern bot5-page__form" },
                React.createElement("h1", { className: "bot5-page__small-title", dangerouslySetInnerHTML: {
                        __html: validation === -1
                            ? 'Не&nbsp;прошла проверка подлинности телеграм-сессии. Напишите, пожалуйста, в&nbsp;поддержку'
                            : 'Экран сейчас не доступен',
                    } }),
                validation === -1
                    ? (React.createElement(Button, { selected: true, view: "flat-info", size: "l", width: "max", onClick: function () {
                            DoHapticFeedback(WebApp);
                            location.href = 'https://t.me/apo_help';
                        } },
                        React.createElement(Icon, { data: PaperPlane }),
                        "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C"))
                    : (React.createElement(Button, { selected: true, view: "flat", size: "l", width: "max", onClick: function () {
                            DoHapticFeedback(WebApp);
                            WebApp.close();
                        } }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"))))
            : ((WebApp && loaded)
                ? (React.createElement(FiltersForm, { state: "ANY", webApp: WebApp }))
                : React.createElement(Spin, { size: "l" })))));
}
