import { CheckedLoggedIn, CreateFormDataFromObject } from "../functions";
/*
 *
 *  Базовый класс API, от него наследуются все разделы API, предоставляет основные шаблоны запросов и полезные функции:
 *
 *      this.PrepareBody(data)
 *          Готовит данные к отправке POST запросом
 *
 *      ! ...UnchangedQuery позволяет не указывать options.dataCallback
 *      ! и просто посылает данные в таком виде, в котором они пришли
 *      ! this.SomeUnchangedQuery<T>(options) <=> this.SomeQuery<T, T>(options)
 *          <=> [IFetchResponse === IPromiseResponse]
 *
 *      this.AlwaysResolvingQuery<IFetchResponse, IPromiseResponse>(options)
 *      this.AlwaysResolvingUnchangedQuery<T>(options)
 *
 *          ! Требует options.defaultValueCallback
 *          Делает запрос, который либо выполнится и обработает ответ, либо вернет
 *          дефолтное значение в случае ошибки
 *
 *          Нужен для отказоустойчивых запросов без дополнительной обработки ошибок
 *
 *      this.RejectingOnErrorQuery<IFetchResponse, IPromiseResponse>(options)
 *      this.RejectingOnErrorUnchangedQuery<T>(options)
 *
 *          Делает запрос, который либо выполнится и обработает ответ, либо вернет сделает reject()
 *
 *          Нужен, когда хотим обработать ошибку сами
 *
 *      this.VoidQuery(options)
 *
 *          Делает запрос, который либо сделает resolve(), либо reject()
 *          (данные никакие не передает)
 *
 *          Нужен, когда делаем запрос на изменение и не получаем никакие данные
 *
 *      this.BooleanQuery(options)
 *
 *          Как VoidQuery только делает либо resolve(true), либо resolve(false)
 *
 *          Нужен, когда запрашиваем статус
 *
 */
var API = /** @class */ (function () {
    function API() {
    }
    API.PrepareBody = function (data) {
        if (!data) {
            return new FormData();
        }
        if (data instanceof FormData) {
            return data;
        }
        if (data instanceof HTMLFormElement) {
            return new FormData(data);
        }
        return CreateFormDataFromObject(data);
    };
    API.AlwaysResolvingQuery = function (options) {
        var _this = this;
        if (!options.publicQuery) {
            CheckedLoggedIn(true);
        }
        return new Promise(function (resolve) {
            fetch(options.customUrl ? options.url : "/api/".concat(options.url), { method: 'POST', body: _this.PrepareBody(options.data) })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    resolve(options.dataCallback(response));
                }
                else {
                    console.error("Error in ".concat(options.url, " (in processing)"), response);
                    typeof options.onError === 'function' && options.onError();
                    resolve(options.defaultValueCallback());
                }
            }, function (error) {
                console.error("Error in ".concat(options.url, " (in promise)"), error);
                typeof options.onError === 'function' && options.onError();
                resolve(options.defaultValueCallback());
            });
        });
    };
    API.AlwaysResolvingUnchangedQuery = function (options) {
        options.dataCallback = function (data) { return data; };
        return this.AlwaysResolvingQuery(options);
    };
    API.RejectingOnErrorQuery = function (options) {
        var _this = this;
        if (!options.publicQuery) {
            CheckedLoggedIn(true);
        }
        return new Promise(function (resolve, reject) {
            fetch(options.customUrl ? options.url : "/api/".concat(options.url), { method: 'POST', body: _this.PrepareBody(options.data) })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    resolve(options.dataCallback(response));
                }
                else {
                    console.error("Error in ".concat(options.url, " (in processing)"), response);
                    typeof options.onError === 'function' && options.onError();
                    reject();
                }
            }, function (error) {
                console.error("Error in ".concat(options.url, " (in promise)"), error);
                typeof options.onError === 'function' && options.onError();
                reject();
            });
        });
    };
    API.RejectingOnErrorUnchangedQuery = function (options) {
        options.dataCallback = function (data) { return data; };
        return this.RejectingOnErrorQuery(options);
    };
    API.VoidQuery = function (options) {
        var _this = this;
        if (!options.publicQuery) {
            CheckedLoggedIn(true);
        }
        return new Promise(function (resolve, reject) {
            fetch(options.customUrl ? options.url : "/api/".concat(options.url), { method: 'POST', body: _this.PrepareBody(options.data) })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    resolve();
                }
                else {
                    console.error("Error in ".concat(options.url, " (in processing)"), response);
                    typeof options.onError === 'function' && options.onError();
                    reject();
                }
            }, function (error) {
                console.error("Error in ".concat(options.url, " (in promise)"), error);
                typeof options.onError === 'function' && options.onError();
                reject();
            });
        });
    };
    API.BooleanQuery = function (options) {
        var _this = this;
        if (!options.publicQuery) {
            CheckedLoggedIn(true);
        }
        return new Promise(function (resolve) {
            fetch(options.customUrl ? options.url : "/api/".concat(options.url), { method: 'POST', body: _this.PrepareBody(options.data) })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    resolve(true);
                }
                else {
                    console.error("Error in ".concat(options.url, " (in processing)"), response);
                    typeof options.onError === 'function' && options.onError();
                    resolve(false);
                }
            }, function (error) {
                console.error("Error in ".concat(options.url, " (in promise)"), error);
                typeof options.onError === 'function' && options.onError();
                resolve(false);
            });
        });
    };
    API.FetchFile = function (options) {
        return fetch(options.url).then(function (res) {
            return res.status === 200 ? res.text() : undefined;
        });
    };
    return API;
}());
export default API;
