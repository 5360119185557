var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from '../../api/api';
var Bot5MiniappAPI = /** @class */ (function (_super) {
    __extends(Bot5MiniappAPI, _super);
    function Bot5MiniappAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = Bot5MiniappAPI;
    Bot5MiniappAPI.ValidateData = function (initData) { return _a.BooleanQuery({
        url: '/bot5/request/ValidateInitDate',
        customUrl: true,
        publicQuery: true,
        data: {
            init_data: initData
        }
    }); };
    Bot5MiniappAPI.CheckState = function (initData, state) { return _a.AlwaysResolvingUnchangedQuery({
        url: '/bot5/request/CheckState',
        customUrl: true,
        publicQuery: true,
        data: {
            init_data: initData,
            state: state
        },
        defaultValueCallback: function () { return ({
            valid: false,
            state_correct: false,
            state_data: ''
        }); }
    }); };
    Bot5MiniappAPI.GetUserData = function (initData) { return _a.AlwaysResolvingQuery({
        url: '/bot5/request/GetUserData',
        customUrl: true,
        publicQuery: true,
        data: {
            init_data: initData,
        },
        dataCallback: function (data) { return data.data; },
        defaultValueCallback: function () { return '{}'; }
    }); };
    Bot5MiniappAPI.SubmitMiniapp = function (initData, state, data, action) {
        if (action === void 0) { action = ''; }
        return _a.VoidQuery({
            url: '/bot5/hooks/miniapp',
            customUrl: true,
            publicQuery: true,
            data: {
                init_data: initData,
                state: state,
                data: data,
                action: action
            }
        });
    };
    Bot5MiniappAPI.SaveDraft = function (initData, state, data) { return _a.VoidQuery({
        url: '/bot5/request/SaveDraft',
        customUrl: true,
        publicQuery: true,
        data: {
            init_data: initData,
            state: state,
            data: data
        }
    }); };
    Bot5MiniappAPI.GetSubjects = function () { return _a.AlwaysResolvingQuery({
        url: '/bot5/request/GetSubjects',
        customUrl: true,
        publicQuery: true,
        dataCallback: function (data) {
            return Object.fromEntries(data.subjects.map(function (_b) {
                var id = _b.id, title = _b.title;
                return [id, title];
            }));
        },
        defaultValueCallback: function () { return ({}); },
    }); };
    Bot5MiniappAPI.GetUserFilters = function (initData) { return _a.RejectingOnErrorUnchangedQuery({
        url: '/bot5/request/GetUserFilters',
        customUrl: true,
        publicQuery: true,
        data: {
            init_data: initData
        }
    }); };
    return Bot5MiniappAPI;
}(API));
export { Bot5MiniappAPI };
