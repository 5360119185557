import * as React from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { InputLabel } from "../../../components/Form/InputLabel";
import LoginAPI from "../../../api/request/login/api";
export default function AuthLoginForm() {
    var searchParams = useSearchParams()[0];
    var retpath = searchParams.get('retpath');
    var _a = useState(''), login = _a[0], setLogin = _a[1];
    var _b = useState(''), loginError = _b[0], setLoginError = _b[1];
    var _c = useState(false), submitting = _c[0], setSubmitting = _c[1];
    var navigate = useNavigate();
    function SubmitLogin(evt) {
        evt.preventDefault();
        var data = new FormData(document.querySelector('#auth-login-form'));
        if (login.trim() === '') {
            setLoginError('Введите, пожалуйста, ваш тг-ник');
            return;
        }
        setLoginError('');
        setSubmitting(true);
        LoginAPI.SendCode(login)
            .then(function (status) {
            setSubmitting(false);
            console.log(status);
            switch (status) {
                case 'forbidden':
                    setLoginError('Нет доступа');
                    break;
                case 'empty':
                    setLoginError('Введите, пожалуйста, ваш тг-ник или почту');
                    break;
                case 'no user':
                    setLoginError('Пользователь не найден');
                    break;
                case 'not signed up':
                    setLoginError("\u0412\u044B \u0435\u0449\u0435 \u043D\u0435 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043B\u0438\u0441\u044C, \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435, \u0432&nbsp;\u0442\u0435\u043B\u0435\u0433\u0440\u0430\u043C-\u0431\u043E\u0442\u0430 <a href=\"https://t.me/cpm_superapp_bot\" class=\"text-link\">@cpm_superapp_bot</a>");
                    break;
                case 'ok':
                    localStorage.setItem('tgusername', login.trim());
                    navigate(retpath ? "/auth/code?retpath=".concat(encodeURIComponent(retpath)) : '/auth/code');
                    break;
                default:
                    setLoginError('Возникла непредвиденная ошибка, попробуйте еще раз позже');
            }
        });
    }
    return (React.createElement("form", { id: "auth-login-form", className: "login-page__form", onSubmit: SubmitLogin },
        React.createElement("div", { className: "login-page__logo" },
            React.createElement("img", { src: "/img/superapp.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F" })),
        React.createElement(InputLabel, { title: "\u0412\u0430\u0448 \u043B\u043E\u0433\u0438\u043D \u0432 \u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C\u0435", name: "login", value: login, valueSetter: setLogin, error: loginError }),
        React.createElement("button", { className: "login-page__button", disabled: submitting }, "\u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u043A\u043E\u0434 \u0432 \u0442\u0435\u043B\u0435\u0433\u0440\u0430\u043C"),
        React.createElement("span", { className: "login-page__annotation annotation" },
            React.createElement(Link, { to: "/auth/signup", className: "text-link" }, "\u041A\u0430\u043A \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F?"))));
}
