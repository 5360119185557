var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button, Select, Spin, Text } from '@gravity-ui/uikit';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Bot5MiniappAPI } from '../api';
import { DoHapticFeedback } from '../index';
export default function FiltersForm(props) {
    var _a, _b, _c;
    var WebApp = props.webApp;
    var _d = useState(false), pending = _d[0], setPending = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(null), initData = _f[0], setInitData = _f[1];
    var _g = useState([]), allSources = _g[0], setAllSources = _g[1];
    var _h = useState([]), allTopics = _h[0], setAllTopics = _h[1];
    useEffect(function () {
        // @ts-ignore
        Bot5MiniappAPI.GetUserFilters(WebApp === null || WebApp === void 0 ? void 0 : WebApp.initData)
            .then(function (_a) {
            var user_filters = _a.user_filters, all_topics = _a.all_topics, all_sources = _a.all_sources;
            setInitData(user_filters);
            setAllSources(all_sources);
            setAllTopics(all_topics);
            setLoading(false);
        });
    }, []);
    return (loading
        ? React.createElement(Spin, { size: "l" })
        : React.createElement("form", { className: "modern bot5-page__form" },
            React.createElement("h1", null, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B"),
            allTopics.length
                ? React.createElement("label", { className: "label" },
                    React.createElement(Text, { className: "label-title" }, "\u0422\u0435\u043C\u044B"),
                    React.createElement(Select, { name: "topics", width: "max", multiple: true, value: ((_a = initData.topics) !== null && _a !== void 0 ? _a : allTopics.map(function (topic) { return topic.id; })).map(function (item) { return String(item); }), options: allTopics.map(function (topic) { return ({ content: topic.name, value: String(topic.id) }); }), onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { topics: val.length && val.length !== allTopics.length ? val.map(function (i) { return +i; }) : null })); }, size: "l", onFocus: function () {
                            DoHapticFeedback(WebApp);
                        } }))
                : null,
            allSources.length
                ? React.createElement("label", { className: "label" },
                    React.createElement(Text, { className: "label-title" }, "\u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A\u0438"),
                    React.createElement(Select, { name: "sources", width: "max", multiple: true, value: ((_b = initData.sources) !== null && _b !== void 0 ? _b : allSources.map(function (source) { return source.id; })).map(function (item) { return String(item); }), options: allSources.map(function (source) { return ({ content: source.title, value: String(source.id) }); }), onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { sources: val.length && val.length !== allSources.length ? val.map(function (i) { return +i; }) : null })); }, size: "l", onFocus: function () {
                            DoHapticFeedback(WebApp);
                        } }))
                : null,
            React.createElement("label", { className: "label" },
                React.createElement(Text, { className: "label-title" }, "\u0421\u043B\u043E\u0436\u043D\u043E\u0441\u0442\u044C"),
                React.createElement(Select, { name: "difficulty", width: "max", multiple: true, value: ((_c = initData.difficulty) !== null && _c !== void 0 ? _c : [1, 2, 3]).map(function (item) { return String(item); }), options: [
                        { content: 'Легкие', value: '1' },
                        { content: 'Средние', value: '2' },
                        { content: 'Сложные', value: '3' },
                    ], onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { difficulty: val.length ? val.map(function (i) { return +i; }) : null })); }, size: "l", onFocus: function () {
                        DoHapticFeedback(WebApp);
                    } })),
            React.createElement("label", { className: "label" },
                React.createElement(Text, { className: "label-title" }, "\u041C\u043E\u0436\u043D\u043E \u043B\u0438 \u0440\u0435\u0448\u0430\u0442\u044C \u0432 \u043C\u0435\u0442\u0440\u043E"),
                React.createElement(Select, { name: "metro", width: "max", value: [initData.metro === null ? '-1' : String(initData.metro)], options: [
                        { content: 'Без разницы', value: '-1' },
                        { content: 'Можно', value: '1' },
                        { content: 'Не получится', value: '0' },
                    ], onUpdate: function (val) { return setInitData(__assign(__assign({}, initData), { metro: val[0] === '-1' ? null : +val[0] })); }, size: "l", onFocus: function () {
                        DoHapticFeedback(WebApp);
                    } })),
            React.createElement(Button, { width: "max", selected: true, view: "flat", size: "l", onClick: function () {
                    setPending(true);
                    // @ts-ignore
                    Bot5MiniappAPI.SubmitMiniapp(WebApp === null || WebApp === void 0 ? void 0 : WebApp.initData, props.state, JSON.stringify(initData), 'SET_FILTERS')
                        .then(
                    // @ts-ignore
                    function () { return WebApp === null || WebApp === void 0 ? void 0 : WebApp.close(); }, function (err) { return console.error('[Miniapp Handler] Something went wrong', err); })
                        .finally(function () {
                        setPending(false);
                    });
                    DoHapticFeedback(WebApp);
                }, loading: pending }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
}
